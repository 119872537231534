'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/account/memberGroup/page',
  memberPageUrl: '/account/memberGroupInfo/listPage',
  getList(code, params) {
    return http.get('/account/memberGroup/list', { params, code });
  },
  getMemberPageById(code, params) {
    return http.get('/account/memberGroupInfo/listPage', { params, code });
  },
  getTagTreeList(code) {
    return http.get('/account/tag/getTagTree', { code });
  },
  getCityList(code, params) {
    return http.get(
      '/authority/dictionaryItem/hierarchyBycode?code=CHINA_AREA',
      {
        params,
        code,
      }
    );
  },
  get(code, id) {
    return http.get(`/account/memberGroup/${id}`, { code });
  },
  getGroupTagsById(code, id) {
    return http.get(`/account/memberGroupDetail/getByGroupId?groupId=${id}`, {
      code,
    });
  },
  getGroupRulesById(code, id) {
    return http.get(`/account/groupRule/getByConditionId?groupId=${id}`, {
      code,
    });
  },
  add(code, data) {
    return http.post('/account/memberGroup/saveAndDetail', data, { code });
  },
  delete(code, params) {
    return http.delete('/account/memberGroup', { params, code });
  },
  runGroupRule(code, params) {
    return http.get(`/account/ruleExecute/groupExecutor`, {
      code,
      params,
    });
  },
  updateAutoSwitch(code, { id, autoSwitch }) {
    return http.put(
      `/account/memberGroup/updateAutoSwitch/${id}/${autoSwitch}`,
      {},
      { code }
    );
  },
  // 分群取消执行
  // CancelRunGroupRule(code, params) {
  //   return http.get(`/account/ruleExecute/groupExecutor`, {
  //     code,
  //     params,
  //   });
  // },
};
