import http from '@/utils/http';

const apiList = {
  integralList: `/account/action/page`, // 积分列表
  updateScore: `/account/action/updateScore`, // 积分修改
  addIntegraItem: `/account/action`, // 新增积分行为
  giftList: `/account/goodsInfo/page`, // 礼品列表
  getUserIsAdmin: `/account/goodsInfo/getUserIsAdmin`, // GET 查询当前登录用户角色是否超管
  addGift: `/account/goodsInfo`, // POST 新增礼品
  delGift: `/account/goodsInfo`, // DELETE 删除礼品
  editGift: `/account/goodsInfo`, // PUT 修改礼品
  giftLogs: `/account/memberGoodsInfo/listPage`, // 礼品兑换记录
  // giftLogs: `/account/memberGoodsInfo/page`,  // 礼品兑换记录
  editLog: `/account/memberGoodsInfo`, // 修改礼品记录
  getAreaOptions: `/authority/dictionaryItem/codes`, // GET 获取地区列表
  getExchangeAddr: `/account/goodsExchangeAddr/page`, // GET 兑换地点
  editExchangeAddr: `/account/goodsExchangeAddr`, // PUT 修改兑换地址信息
  addExchangeAddr: `/account/goodsExchangeAddr`, // POST 修改兑换地址信息
  delExchangeAddr: `/account/goodsExchangeAddr`, // DELETE 删除兑换地址信息
  getGoodsIdList: `/account/goodsInfo/page`, // GET 礼品ID列表
};

export default {
  pageUrl: '/account/action/page',
  giftPageUrl: '/account/goodsInfo/page',
  logPageUrl: '/account/memberGoodsInfo/listPage',
  addrPageUrl: '/account/goodsExchangeAddr/page',
  add(code, data) {
    return http.post('/account/action', data, { code });
  },
  update(code, data) {
    return http.put('/account/action/updateScore', data, { code });
  },
  getUserIsAdmin(code) {
    return http.get('/account/goodsInfo/getUserIsAdmin', { code });
  },
  addGift(code, data) {
    return http.post('/account/goodsInfo', data, { code });
  },
  updateGift(code, data) {
    return http.put('/account/goodsInfo', data, { code });
  },
  deleteGift(code, params) {
    return http.delete('/account/goodsInfo', { params, code });
  },
  updateLog(code, data) {
    return http.put('/account/memberGoodsInfo', data, { code });
  },

  getAreaDeptOptions(
    data = {
      codes: 'AREA_DEPT',
    }
  ) {
    return http({
      method: 'GET',
      url: apiList.getAreaOptions,
      data: data || {},
    });
  },

  getIndustryLv1Options(
    data = {
      codes: 'INDUSTRY',
    }
  ) {
    return http({
      method: 'GET',
      url: apiList.getAreaOptions,
      data: data || {},
    });
  },

  getCUSTOMETYPEOptions(
    data = {
      codes: 'CUSTOMETYPE',
    }
  ) {
    return http({
      method: 'GET',
      url: apiList.getAreaOptions,
      data: data || {},
    });
  },

  getJobTitleOptions(
    data = {
      codes: 'Job Title',
    }
  ) {
    return http({
      method: 'GET',
      url: apiList.getAreaOptions,
      data: data || {},
    });
  },

  getGoodsIdList(data = {}) {
    return http({
      method: 'GET',
      url: apiList.getGoodsIdList,
      data,
    });
  },

  editExchangeAddr(data = {}) {
    return http({
      method: 'PUT',
      url: apiList.editExchangeAddr,
      data,
    });
  },

  addExchangeAddr(data = {}) {
    return http({
      method: 'POST',
      url: apiList.addExchangeAddr,
      data,
    });
  },

  delExchangeAddr(data = {}) {
    return http({
      method: 'DELETE',
      url: apiList.delExchangeAddr,
      data,
    });
  },
};
