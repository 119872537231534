<template>
  <!-- <div class="iss-main"> -->
  <div class="center-box">
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <div class="iss-main">
        <a-card title="基础信息" class="iss-card">
          <a-row :gutter="20">
            <a-col :span="12">
              <a-form-item name="name" label="群组名称">
                <a-input v-model:value="form.name" :disabled="isWatch" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="remark" label="备注">
                <a-input v-model:value="form.remark" :disabled="isWatch" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item
            label="群组标签"
            v-if="!(isWatch && dynamicTags.length === 0)"
          >
            <a-row :gutter="20">
              <a-col :span="12">
                <a-tree
                  style="max-height: 267px; overflow-y: auto"
                  :disabled="isWatch"
                  checkable
                  v-model:checked-keys="treeDefaultChecked"
                  :tree-data="tagTree"
                  :replace-fields="{ key: 'tagCode', title: 'tagName' }"
                  @check="tagTreeCheckChange"
                />
              </a-col>
              <a-col :span="12">
                <a-space id="l-space" class="iss-space">
                  <a-tag
                    :key="tag.tagCode"
                    v-for="tag in dynamicTags"
                    color="#D6E2FF"
                    :closable="!isWatch"
                    style="
                      border: 1px solid #5979f8;
                      color: #5979f8;
                      height: 32px;
                      line-height: 29px;
                      font-size: 14px;
                    "
                    @close="handleClose(tag)"
                  >
                    <span style="padding-left: 2px">{{ tag.tagName }}</span>
                  </a-tag>
                </a-space>
              </a-col>
            </a-row>
          </a-form-item>
        </a-card>
      </div>
      <div class="iss-main mb-0">
        <a-card title="群组规则" class="iss-card">
          <div class="iss-regulations">
            <!--分群规则 start-->
            <ul class="condition-group-list">
              <!--条件组列表 -->
              <li
                class="condition-group-item"
                :key="index"
                v-for="(item, index) in form.groupRuleList"
              >
                <div class="condition-group-type-wrapper">
                  <a class="and-btn">且</a>
                </div>
                <ul class="condition-list">
                  <!--条件列表 -->
                  <li
                    class="condition-item"
                    :key="conditionIndex"
                    v-for="(condition, conditionIndex) in item"
                  >
                    <div class="condition-type-wrapper">
                      <span class="or-btn">或</span>
                    </div>
                    <div class="condition-wrapper">
                      <table class="condition-table">
                        <!--属性满足-->
                        <tr v-if="condition.type === 'property'">
                          <td style="width: 14%">
                            <span class="condition-type"> 属性满足 </span>
                          </td>
                          <td style="width: 18%">
                            <a-form-item
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'col',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="condition.col"
                                :disabled="isWatch"
                                placeholder="请选择属性"
                                @change="
                                  handleChangeProperty($event, condition)
                                "
                              >
                                <a-select-option
                                  v-for="p in propertyList"
                                  :key="p.colCode"
                                  :value="p.colCode"
                                >
                                  {{ p.colName }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <td style="width: 15%">
                            <a-form-item
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'operator',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="condition.operator"
                                :disabled="isWatch"
                                placeholder="请选择操作符"
                              >
                                <a-select-option
                                  v-for="ol in actionList"
                                  :key="ol.value"
                                  :value="ol.value"
                                >
                                  {{ ol.label }}
                                </a-select-option>
                                <!--                              <a-select-option-->
                                <!--                                v-for="o in operatorListMap[condition.dataType]"-->
                                <!--                                :key="o.symbol"-->
                                <!--                                :value="o.symbol"-->
                                <!--                              >-->
                                <!--                                {{ o.symbolName }}-->
                                <!--                              </a-select-option>-->
                              </a-select>
                            </a-form-item>
                          </td>
                          <td
                            v-if="condition.col === 'country'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="condition.operator !== '('"
                              v-model="condition.value"
                              aria-required="true"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    areaOptions.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入国家…"
                            />
                          </td>
                          <td
                            v-else-if="condition.col === 'region'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="condition.operator !== '('"
                              v-model="condition.value"
                              aria-required="true"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    areaDeptOptions.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入地区..."
                            />
                          </td>
                          <!--                    行业    -->
                          <td
                            v-else-if="condition.col === 'industry_lv1'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="condition.operator !== '('"
                              v-model="condition.value"
                              aria-required="true"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    industryLv1Options.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入行业..."
                            />
                          </td>
                          <td
                            v-else-if="condition.col === 'customer_type'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="condition.operator !== '('"
                              v-model="condition.value"
                              aria-required="true"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    CUSTOMETYPEOptions.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入客户类型..."
                            />
                          </td>
                          <td
                            v-else-if="condition.col === 'job_title'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="condition.operator !== '('"
                              v-model="condition.value"
                              aria-required="true"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    JobTitleOptions.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入客户类型..."
                            />
                          </td>
                          <td
                            v-else-if="condition.col === 'is_activate'"
                            style="width: 53%"
                          >
                            <a-form-item
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'value',
                              ]"
                              :rules="required"
                            >
                              <el-select
                                v-if="condition.operator !== '('"
                                v-model="condition.value"
                                style="width: 50%"
                                aria-required="true"
                                placeholder="请选择"
                              >
                                <el-option
                                  v-for="item in is_activateOptions"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                />
                              </el-select>
                            </a-form-item>
                          </td>
                          <td
                            v-else-if="condition.dataType === 'time'"
                            style="width: 53%"
                          >
                            <a-form-item
                              v-if="condition.operator !== '('"
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'value',
                              ]"
                              :rules="required"
                            >
                              <el-date-picker
                                v-model="condition.value"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              />
                            </a-form-item>
                            <a-form-item
                              v-if="
                                ['()', '[)', '(]', '[]'].includes(
                                  condition.operator
                                )
                              "
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'secondValue',
                              ]"
                              :rules="required"
                            >
                              <el-date-picker
                                v-model="condition.secondValue"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              />
                            </a-form-item>
                          </td>
                          <td
                            v-else-if="
                              condition.col === 'province' ||
                              condition.col === 'city'
                            "
                            style="width: 53%"
                          >
                            <a-form-item
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'value',
                              ]"
                              :rules="required"
                              style="width: 100%"
                            >
                              <a-select
                                v-model:value="condition.value"
                                show-search
                                :disabled="isWatch"
                                placeholder="请选择"
                              >
                                <a-select-option
                                  v-for="item in list[condition.col]"
                                  :key="item.id"
                                  :value="item.itemCode"
                                  :item="item"
                                >
                                  {{ item.itemNameCn }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <!--            属性满足输入框            -->
                          <td v-else style="width: 53%">
                            <div style="display: flex">
                              <a-form-item
                                style="width: 100%"
                                :name="[
                                  'groupRuleList',
                                  index,
                                  conditionIndex,
                                  'value',
                                ]"
                                :rules="required"
                                v-if="condition.operator !== '('"
                              >
                                <a-select
                                  v-if="['gender'].includes(condition.col)"
                                  v-model:value="condition.value"
                                  :disabled="isWatch"
                                  :options="genderList"
                                  placeholder="请选择"
                                />
                                <a-select
                                  v-else-if="
                                    ['receive_consult'].includes(condition.col)
                                  "
                                  v-model:value="condition.value"
                                  :disabled="isWatch"
                                  :options="receiveConsultList"
                                  placeholder="请选择"
                                />
                                <a-textarea
                                  v-else-if="
                                    ['position', 'company'].includes(
                                      condition.col
                                    )
                                  "
                                  v-model:value="condition.value"
                                  auto-size
                                  :disabled="isWatch"
                                  placeholder="请输入..."
                                />
                                <!--               省份 城市      -->
                                <a-select
                                  v-else-if="
                                    ['province', 'city'].includes(condition.col)
                                  "
                                  v-model:value="condition.value"
                                  show-search
                                  :disabled="isWatch"
                                  placeholder="请选择"
                                >
                                  <a-select-option
                                    v-for="item in list[condition.col]"
                                    :key="item.id"
                                    :value="item.itemCode"
                                    :item="item"
                                  >
                                    {{ item.itemNameCn }}
                                  </a-select-option>
                                </a-select>

                                <!--                              <a-select-->
                                <!--                                  v-model:value="condition.value"-->
                                <!--                                  :disabled="isWatch"-->
                                <!--                                  placeholder="请选择行业"-->
                                <!--                                  v-else-if="['industry_lv1_code'].includes(condition.col)"-->
                                <!--                              >-->
                                <!--                                <a-select-option-->
                                <!--                                    v-for="item in industryList"-->
                                <!--                                    :key="item.id"-->
                                <!--                                    :value="item.itemCode"-->
                                <!--                                    :item="item"-->
                                <!--                                >-->
                                <!--                                  {{item.itemNameCn}}-->
                                <!--                                </a-select-option>-->
                                <!--                              </a-select>-->

                                <a-textarea
                                  v-else
                                  v-model:value="condition.value"
                                  :disabled="isWatch"
                                  auto-size
                                  placeholder="请输入..."
                                />
                              </a-form-item>
                              <a-form-item
                                style="width: 2%"
                                v-if="
                                  ['()', '[)', '(]', '[]'].includes(
                                    condition.operator
                                  )
                                "
                              >
                                <span> - </span>
                              </a-form-item>

                              <a-form-item
                                style="width: 49%"
                                :name="[
                                  'groupRuleList',
                                  index,
                                  conditionIndex,
                                  'secondValue',
                                ]"
                                :rules="required"
                                v-if="
                                  ['()', '[)', '(]', '[]'].includes(
                                    condition.operator
                                  )
                                "
                              >
                                <a-input
                                  :disabled="isWatch"
                                  v-model:value="condition.secondValue"
                                  placeholder="请输入..."
                                />
                              </a-form-item>
                              <span
                                style="
                                  margin-left: 10px;
                                  margin-bottom: 5px;
                                  display: inline-block;
                                "
                                v-if="condition.col === 'email'"
                              >
                                <a-tooltip placement="top">
                                  <template #title>
                                    1.@issmart.com.cn可进行邮箱后缀匹配；<br />
                                    2.issmart@可进行前缀匹配；
                                  </template>
                                  <QuestionCircleOutlined />
                                </a-tooltip>
                              </span>
                            </div>
                          </td>
                          <td>
                            <a-form-item>
                              <a
                                class="remove-btn"
                                v-if="!isWatch"
                                @click="removeRule(index, conditionIndex)"
                              />
                            </a-form-item>
                          </td>
                        </tr>
                        <!--行为满足-->
                        <tr v-if="condition.type === 'behavior'">
                          <td style="width: 14%">
                            <span class="condition-type"> 行为满足 </span>
                          </td>
                          <td style="width: 18%">
                            <a-form-item
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'col',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="condition.col"
                                :disabled="isWatch"
                                placeholder="请选择行为"
                                @change="handleChangeAction($event, condition)"
                              >
                                <a-select-option
                                  v-for="ap in actionPropertyList"
                                  :key="ap.colCode"
                                  :value="ap.colCode"
                                >
                                  {{ ap.colName }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <td style="width: 15%">
                            <a-form-item
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'action',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                :disabled="isWatch"
                                v-model:value="condition.action"
                                placeholder="请选择"
                              >
                                <a-select-option
                                  v-for="o in condition.behaviorActionList"
                                  :key="o.value"
                                  :value="o.value"
                                >
                                  {{
                                    o.name +
                                    `${o.unit ? '（' + o.unit + '）' : ''}`
                                  }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <td style="width: 18%">
                            <a-form-item
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'operator',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="condition.operator"
                                :disabled="isWatch"
                                placeholder="请选择操作符"
                              >
                                <a-select-option
                                  v-for="ol in operatorList"
                                  :key="ol.value"
                                  :value="ol.value"
                                >
                                  {{ ol.label }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <td style="width: 30%">
                            <div style="display: flex">
                              <a-form-item
                                :name="[
                                  'groupRuleList',
                                  index,
                                  conditionIndex,
                                  'value',
                                ]"
                                :rules="required"
                                :style="{
                                  width:
                                    condition.operator === '[]'
                                      ? '49%'
                                      : '100%',
                                }"
                              >
                                <a-input-number
                                  :disabled="isWatch"
                                  v-model:value="condition.value"
                                  :min="1"
                                  :precision="0"
                                  placeholder="请输入正整数"
                                />
                              </a-form-item>
                              <a-form-item style="width: 2%">
                                <span v-if="condition.operator === '[]'">
                                  -
                                </span>
                              </a-form-item>

                              <a-form-item
                                style="width: 49%"
                                :name="[
                                  'groupRuleList',
                                  index,
                                  conditionIndex,
                                  'secondValue',
                                ]"
                                :rules="required"
                                v-if="condition.operator === '[]'"
                              >
                                <a-input-number
                                  :disabled="isWatch"
                                  :min="1"
                                  :precision="0"
                                  v-model:value="condition.secondValue"
                                  placeholder="请输入正整数"
                                />
                              </a-form-item>
                            </div>
                          </td>
                          <td style="width: 5%">
                            <a-form-item>
                              <div>
                                <!--                              <span>-->
                                <!--                                {{-->
                                <!--                                  condition.action === 'count' ? '次' : '分钟'-->
                                <!--                                }}-->
                                <!--                              </span>-->

                                <span v-if="condition.operator === '[]'">
                                  <a-tooltip
                                    title="包含起始值与结束值"
                                    placement="top"
                                  >
                                    <QuestionCircleOutlined />
                                  </a-tooltip>
                                </span>
                                <a
                                  class="remove-btn"
                                  v-if="!isWatch"
                                  @click="removeRule(index, conditionIndex)"
                                />
                              </div>
                            </a-form-item>
                          </td>
                        </tr>
                        <!--行为满足2-->
                        <tr v-if="condition.type === 'behavior'">
                          <td></td>
                          <td colspan="3">
                            <a-form-item
                              :name="[
                                'groupRuleList',
                                index,
                                conditionIndex,
                                'timeValue',
                              ]"
                              :rules="required"
                            >
                              <a-range-picker
                                v-model:value="condition.timeValue"
                                style="width: 100%"
                                show-time
                                value-format="yyyy-MM-DD HH:mm:ss"
                                :disabled="isWatch"
                                @change="
                                  changeBehaviorTime(
                                    index,
                                    conditionIndex,
                                    condition
                                  )
                                "
                              />
                            </a-form-item>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </li>

                  <!--条件添加按钮 -->
                  <li class="condition-item">
                    <div class="condition-type-wrapper">
                      <a class="or-btn" v-if="!isWatch"></a>
                      <div class="condition-type-list">
                        <a
                          class="condition-type-a"
                          @click="addCondition('property', index)"
                        >
                          属性满足
                        </a>
                        <a
                          class="condition-type-a"
                          @click="addCondition('behavior', index)"
                        >
                          行为满足
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <!--条件组添加按钮 -->
              <li class="condition-group-item">
                <div class="condition-group-type-wrapper">
                  <a class="and-btn" v-if="!isWatch"></a>
                  <div class="condition-type-list" style="top: -33%">
                    <a
                      class="condition-type-a"
                      @click="addConditionGroup('property')"
                    >
                      属性满足
                    </a>
                    <a
                      class="condition-type-a"
                      @click="addConditionGroup('behavior')"
                    >
                      行为满足
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <!--分群规则 end-->
          </div>
        </a-card>
      </div>
    </a-form>
  </div>
  <!-- </div> -->
  <div class="iss-main">
    <a-space>
      <a-button type="primary" v-if="!isWatch" @click="handleSave">
        保存
      </a-button>
      <a-button @click="$router.go(-1)"> 返回 </a-button>
    </a-space>
  </div>
</template>

<script>
import {
  Card,
  Form,
  Row,
  Col,
  Space,
  Tag,
  Tree,
  Select,
  message,
  DatePicker,
  InputNumber,
  Tooltip,
} from 'ant-design-vue';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { useRouter, useRoute } from 'vue-router';
import clusteringApi from '@/api/clustering.js';
import integralApi from '@/api/integral.js';
import tagApi from '@/api/tag.js';
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, ref } from 'vue';
import { getCurrentInstance } from 'vue';

export default {
  components: {
    ACard: Card,
    AForm: Form,
    AFormItem: Form.Item,
    AInputNumber: InputNumber,
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    ATree: Tree,
    ATag: Tag,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    ATooltip: Tooltip,
    QuestionCircleOutlined,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref();
    const ruleFormRef = ref();
    const groupId = route.params.id;
    const { ctx } = getCurrentInstance();
    const _this = ctx;
    const list = reactive({
      province: [],
      city: [],
    });
    const required = {
      required: true,
      message: '不能为空',
    };
    const isWatch = route.params.status === 'watch';
    const form = reactive({
      name: null,
      remark: null,
      groupRuleList: [],
    });
    const state = reactive({
      code: '',
      industryList: [],
      rules: {
        name: [
          {
            required: true,
            message: '群组名称不能为空',
            trigger: 'blur',
          },
          {
            max: 50,
            message: '长度不能超过50',
            trigger: 'change',
          },
        ],
        remark: [
          {
            max: 400,
            message: '长度不能超过400',
            trigger: 'change',
          },
        ],
      },
      treeLoading: false,
      tagTree: [],
      defaultProps: {
        children: 'children',
        label: 'tagName',
      },
      treeDefaultChecked: [],
      dynamicTags: [],
      // groupRuleList: [],
      operatorListMap: {},
      propertyList: [],
      actionPropertyList: [], //动作属性
      genderList: [
        { value: '0', label: '男' },
        { value: '1', label: '女' },
      ],
      receiveConsultList: [
        { value: '1', label: '接受' },
        { value: '0', label: '不接受' },
      ],
      operatorList: [
        { label: '等于', value: '=' },
        { label: '不等于', value: '!=' },
        { label: '大于', value: '>' },
        { label: '小于', value: '<' },
        { label: '介于', value: '[]' },
      ],
      // 写死操作符
      actionList: [{ label: '等于', value: '=' }],
      behaviorAllList: [
        { label: '总次数', value: 'count' },
        { label: '总时长', value: 'total_time' },
      ],
      behaviorLiveList: [{ label: '总时长', value: 'total_time' }],
      behaviorCountList: [{ label: '总次数', value: 'count' }],
      //省市
      cityList: [],

      areaOptions: [],
      segment: {
        name: '',
        remark: '',
        label: 'label',
        memberGroupDetailList: [],
        conditionGroupList: [],
        groupRuleList: [],
      },
      is_activateOptions: [
        {
          value: '0',
          label: '未激活',
        },
        {
          value: '1',
          label: '待激活',
        },
        {
          value: '2',
          label: '已激活',
        },
      ],
      loading: false,
      areaDeptOptions: [],
      CUSTOMETYPEOptions: [],
      industryLv1Options: [],
      JobTitleOptions: [],
    });
    // 点击编辑数据
    const initTagDetails = () => {
      const id = route.params.id;
      if (id) {
        if (id === 'add') {
          state.code = 'group:add';
        } else {
          state.code = 'group:update';
          clusteringApi.get(state.code, id).then(data => {
            Object.assign(form, data);
          });
          clusteringApi.getGroupTagsById(state.code, id).then(data => {
            state.dynamicTags = data;
          });
          clusteringApi.getGroupRulesById(state.code, id).then(data => {
            for (const key in data) {
              if (Object.hasOwnProperty.call(data, key)) {
                let element = data[key];
                element = element.map(item => {
                  // 增加判断，返回的数据有 actionType的处理下
                  if (item.actionType) {
                    item.col = item.col + ',' + item.actionType;
                  }
                  const temp = state.actionPropertyList.find(
                    i => i.colCode === item.col
                  );
                  item.behaviorActionList = temp?.action || [];

                  let params = {
                    ...item,
                  };
                  if (params.timeValue) {
                    params.timeValue = params.timeValue.split('@@');
                  }
                  return params;
                });
                data[key] = element;
              }
            }
            form.groupRuleList = Object.values(data);
          });
        }
      }
    };
    const initTagTreeList = () => {
      state.treeLoading = true;
      clusteringApi
        .getTagTreeList(state.code)
        .then(data => {
          const tempTreeData = [];
          const keyAndVal = Object.entries(data);
          const allItems = [];
          keyAndVal.forEach(val => {
            tempTreeData.push({
              tagName: val[1].typeName,
              children: val[1].tagList,
              tagCode: `${val[0]}-${new Date().getTime()}`,
            });
            allItems.push(...val[1].tagList);
          });
          state.tagTree = tempTreeData;

          // 处理默认选中
          let tempTreeDefaultChecked = [];
          state.dynamicTags.map(item => {
            allItems.some(treeItem => {
              if (item.tagCode === treeItem.tagCode) {
                tempTreeDefaultChecked.push(treeItem.tagCode);
                item.tagName = treeItem.tagName;
                return true;
              }
            });
          });

          state.treeDefaultChecked = tempTreeDefaultChecked;
        })
        .finally(() => {
          state.treeLoading = false;
        });
    };
    const initPropertySelector = () => {
      return tagApi.getPropertySelector(state.code);
    };
    const initPropertyRule = () => {
      return tagApi.getPropertyRule(state.code);
    };
    // 调取行为接口
    const initActionSelector = () => {
      return tagApi.getActionSelector(state.code);
    };
    const handleChangeAction = (value, rule) => {
      rule.operator = '';
      rule.value = '';
      rule.secondValue = '';
      const temp = state.actionPropertyList.find(i => i.colCode === value);
      rule.behaviorActionList = temp.action;
      rule.dataType = temp.dataType;
    };

    const handleClose = tag => {
      // 删除临时展示数据
      state.dynamicTags.splice(state.dynamicTags.indexOf(tag), 1);
      state.treeDefaultChecked = state.treeDefaultChecked.filter(
        item => item != tag.tagCode
      );
      // 有时候不触发change方法，手动调用
      // tagTreeCheckChange();
    };
    const tagTreeCheckChange = (checkedKeys, { checkedNodes }) => {
      // 标签展示数据
      state.dynamicTags = checkedNodes
        .map(({ props }) => {
          let flag = state.tagTree.some(item => item.tagCode === props.tagCode);
          if (!flag) {
            return {
              tagName: props.tagName,
              tagCode: props.tagCode,
              groupId: groupId,
            };
          }
        })
        .filter(item => {
          if (item) {
            return true;
          } else {
            return false;
          }
        });
    };
    const addConditionGroup = type => {
      form.groupRuleList.push([]);
      addCondition(type, form.groupRuleList.length - 1);
    };
    const addCondition = (type, index) => {
      form.groupRuleList[index].push({
        actionType: '',
        conditionId: index + 1,
        action: null,
        col: null,
        operator: null,
        value: '',
        dataType: '',
        type,
      });
    };
    const removeRule = (index, conditionIndex) => {
      form.groupRuleList[index].splice(conditionIndex, 1);
      if (!form.groupRuleList[index].length) {
        form.groupRuleList.splice(index, 1);
      }
    };
    // 保存
    const handleSave = () => {
      if (form.groupRuleList.length === 0) {
        return message.error('请配置至少一条规则');
      }
      const paramsList = form.groupRuleList.map(item => {
        item = item.map(item => {
          let params = { ...item };
          if (item.col.indexOf(',')) {
            params.actionType = item.col.split(',')[1];
            params.col = item.col.split(',')[0];
          }

          if (params.timeValue) {
            params.timeValue = params.timeValue.join('@@');
          }
          return params;
        });
        console.log('item2222', item);
        return item;
      });
      const memberGroupDetailList = state.dynamicTags.map(item => {
        return {
          tagCode: item.tagCode,
          groupId: item.groupId,
        };
      });
      if (memberGroupDetailList.length === 0) {
        return message.error('群组标签不能为空');
      }
      formRef.value.validate().then(() =>
        clusteringApi
          .add(state.code, {
            ...form,
            memberGroupDetailList: memberGroupDetailList,
            groupRuleList: paramsList.flat(),
          })
          .then(() => {
            message.success(t('save1.ok'));
            router.go(-1);
          })
      );
    };

    const save = async () => {
      const { id, operate, dynamicTags, segment } = state;
      const msgType = operate === 'edit' ? '修改' : '新建';
      state.segment.id = id;

      let isValidate = await ruleFormRef.value.validate().catch(() => {});

      // 验证不通过
      if (!isValidate) return message.error('验证不通过');

      state.loading = true;
      // 处理标签参数
      dynamicTags.map(item => {
        segment.memberGroupDetailList.push({
          groupId: id,
          tagCode: item.tagCode,
        });
      });

      // 格式化规则数组
      segment.conditionGroupList.map(item => {
        segment.groupRuleList.push(
          ...item.ruleList.map(rule => {
            if (rule.col !== 'country') return rule;
            // 把中文地区 转为替换为 英文参数
            return Object.assign({}, rule, {
              value: areaChEntransformation(rule.value, 'En'),
            });
          })
        );
      });

      Reflect.deleteProperty(segment, 'conditionGroupList');

      const res = await clusteringApi.add(state.code, segment);
      res
        ? message.success(msgType + '分组成功')
        : message.error(msgType + '分组失败');
      state.loading = false;
      if (!res) return;

      _this.$destroy();
      router.replace({ name: 'clustering' });
    };

    const areaChEntransformation = (area, targetLanguage) => {
      if (targetLanguage === 'Ch') {
        const areaObj = state.areaOptions.find(areaObj => areaObj.en === area);
        return areaObj ? areaObj.value : area;
      }

      if (targetLanguage === 'En') {
        const areaObj = state.areaOptions.find(
          areaObj => areaObj.value === area
        );
        return areaObj ? areaObj.en : area;
      }
    };

    // 获取tag Tree数据

    // 获取地区选择列表
    const getAreaOptions = async () => {
      const res = await integralApi.getAreaOptions();
      const areas = Object.keys(res.data.AREA);
      state.areaOptions = areas.map(key => ({
        en: key,
        value: res.data.AREA[key],
      }));
    };
    const handleChangeProperty = (value, condition) => {
      condition.operator = '';
      condition.value = '';
      condition.secondValue = '';
      const temp = state.propertyList.find(i => i.colCode === value);
      condition.dataType = temp.dataType;
    };
    const handleChange = (index, conditionIndex) => {
      state.propertyList.forEach(item => {
        if (
          item.value ==
          state.segment.conditionGroupList[index].ruleList[conditionIndex].col
        ) {
          state.segment.conditionGroupList[index].ruleList[
            conditionIndex
          ].dataType = item.dataType;
          return;
        }
      });
    };
    const changeBehaviorTime = (index, conditionIndex) => {
      let secondValueArray =
        form.groupRuleList[index][conditionIndex].timeValue;
      form.groupRuleList[index][conditionIndex].timeValue = secondValueArray;
    };
    //获取省市
    const getPCList = () => {
      return clusteringApi.getCityList('', { hierarchy: 2 });
    };
    const getCityList = () => {
      return clusteringApi.getCityList('', { hierarchy: 1 });
    };

    /**
     * 获取行为可选属性
     **/
    const getBehaviorList = () =>
      tagApi
        .getBehaviorList()
        .then(() => {})
        .catch(error => {
          console.error(error);
          state.loading = false;
        });
    // 行业接口
    tagApi.getItemTree('', { code: 'INDUSTRY' }).then(data => {
      state.industryList = data;
    });

    Promise.all([
      initActionSelector(),
      getPCList(),
      getCityList(),
      initPropertySelector(),
      initPropertyRule(),
    ]).then(values => {
      console.log('values', values);
      // 行为列表
      values[0].behavior.forEach(item => {
        if (item.actionType) {
          item.colCode = item.colCode + ',' + item.actionType;
          item.col = item.colCode + ',' + item.actionType;
        }
      });
      state.actionPropertyList = values[0].behavior;
      //  省市
      list.city = values[1];
      list.province = values[2];
      //  行为
      state.propertyList = values[3].contact;
      state.operatorListMap = values[4];
      initTagDetails();
    });

    initTagTreeList();

    return {
      isWatch,
      required,
      formRef,
      ruleFormRef,
      form,
      ...toRefs(state),
      getBehaviorList,
      changeBehaviorTime,
      handleChange,
      handleChangeProperty,
      getAreaOptions,
      areaChEntransformation,
      addCondition,
      save,
      list,
      handleSave,
      removeRule,
      addConditionGroup,
      tagTreeCheckChange,
      handleClose,
      handleChangeAction,
      initPropertyRule,
      initPropertySelector,
      initTagTreeList,
      initTagDetails,
    };
  },
};
</script>

<style scoped>
.iss-main.mb-0 {
  margin-bottom: 0px;
}
.iss-space {
  flex-wrap: wrap;
}
.center-box {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  border-radius: 8px;
}
.iss-regulations .condition-group-item {
  display: flex;
  padding-bottom: 30px;
}
.iss-regulations .condition-group-type-wrapper {
  position: relative;
}
.iss-regulations .condition-group-type-wrapper:before {
  content: '';
  position: absolute;
  display: block;
  top: 36px;
  bottom: -30px;
  left: 50%;
  width: 3px;
  height: 107%;
  transform: translateX(-50%);
  background-color: #adc3ff;
}

.iss-regulations
  .condition-group-item:last-child
  .condition-group-type-wrapper:before {
  display: none;
}

.iss-regulations .and-btn {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  background-color: #85a1ff;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.iss-regulations .and-btn:before {
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-group-item:last-child .and-btn:before {
  content: '+';
  font-size: 30px;
  top: -2px;
}

.iss-regulations .remove-btn {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: rgb(253, 0, 0);
  text-align: center;
  border-radius: 100%;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.iss-regulations .remove-btn:before {
  content: '✕';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-group-item:first-child .remove-btn:before {
  content: '✕';
}

.iss-regulations .condition-group-item:last-child .remove-btn:before {
  content: '✕';
  font-size: 30px;
  top: -2px;
}

.iss-regulations .condition-type-list {
  display: none;
  vertical-align: middle;
}

.iss-regulations .condition-type-wrapper:hover .condition-type-list,
.iss-regulations .condition-group-type-wrapper:hover .condition-type-list {
  display: inline-block;
}

.iss-regulations .condition-type-a {
  display: block;
  width: auto;
  height: 24px;
  line-height: 24px;
  padding: 0px 12px;
  border-radius: 24px;
  margin: 5px;
  color: #5979f8;
  background-color: #d6e2ff;
}

.iss-regulations .condition-type-a:hover {
  color: #fff;
  background-color: #5979f8;
  text-decoration: none;
}

.iss-regulations .or-btn {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  color: #5979f8;
  text-align: center;
  background-color: #d6e2ff;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.iss-regulations .condition-item:last-child .or-btn {
  color: #fff;
  background-color: #85a1ff;
  border: 1px solid #d6e2ff;
}

.iss-regulations .or-btn:before {
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-item:last-child .or-btn:before {
  content: '+';
  font-size: 26px;
  top: -2px;
}

.iss-regulations .condition-list {
  padding-left: 30px;
  width: 100%;
}

.iss-regulations .condition-item {
  display: flex;
}
.condition-type-list {
  position: absolute;
  width: 100px;
  top: 0;
}
.iss-regulations .condition-wrapper {
  width: 100%;
  white-space: nowrap;
}

.iss-regulations .condition-type-wrapper {
  position: relative;
  padding-top: 15px;
}

.iss-regulations .condition-type-wrapper:before {
  content: '';
  position: absolute;
  display: block;
  top: 49px;
  bottom: -30px;
  left: 50%;
  width: 1px;
  transform: translateX(-50%);
  background-color: #ebeff1;
}

.iss-regulations .condition-item:last-child .condition-type-wrapper:before {
  display: none;
}

.iss-regulations .condition-table {
  width: 100%;
}

.iss-regulations .condition-table td {
  padding: 30px 5px 0 5px;
}

.iss-regulations .condition-type {
  display: inline-block;
  color: #5979f8;
  padding-left: 16px;
}

.iss-regulations .condition-close {
  cursor: pointer;
}

.condition-table .ant-select {
  width: 100%;
}
.ant-space-item .ant-tag-has-color .anticon-close {
  color: #000;
}
.iss-regulations {
  min-height: 315px;
}
.ant-input-number {
  width: 100%;
}
</style>
